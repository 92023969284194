import BigNumber from 'bignumber.js';
import poolsConfig from '../../config/constants/pools';
import sousChefABI from '../../config/abi/sousChef.json';
import udeABI from '../../config/abi/ude.json';
import chefABI from '../../config/abi/masterchef.json';
import wbnbABI from '../../config/abi/weth.json';
import multicall from '../../utils/multicall';
import {getAddress, getMasterChefAddress} from '../../utils/addressHelpers';
import tokens from '../../config/tokens';

export const fetchPoolsBlockLimits = async () => {
    const poolsWithEnd1 = poolsConfig.filter((p) => (p.sousId !== 0));
    const poolsWithEnd2 = poolsWithEnd1.filter((p) => (p.sousId !== 6));
    const poolsWithEnd3 = poolsWithEnd2.filter((p) => (p.sousId !== 7));
    const poolsWithEnd = poolsWithEnd3
    const callsStartBlock = poolsWithEnd.map((poolConfig) => ({
            address: getAddress(poolConfig.contractAddress),
            name: 'startBlock',
        }));
    const callsEndBlock = poolsWithEnd.map((poolConfig) => ({
            address: getAddress(poolConfig.contractAddress),
            name: 'bonusEndBlock',
        }));
    const starts = await multicall(sousChefABI, callsStartBlock);
    const ends = await multicall(sousChefABI, callsEndBlock);
    return poolsWithEnd.map((udePoolConfig, index) => {
        const startBlock = starts[index];
        const endBlock = ends[index];
        return {
            sousId: udePoolConfig.sousId,
            startBlock: new BigNumber(startBlock).toJSON(),
            endBlock: new BigNumber(endBlock).toJSON(),
        };
    });
};
export const fetchPoolsTotalStaking = async () => {
    const nonBnbPools = poolsConfig.filter((p) => p.stakingToken.symbol !== 'BNB');
    const bnbPool = poolsConfig.filter((p) => p.stakingToken.symbol === 'BNB');
    const callsNonBnbPools = nonBnbPools.map((poolConfig) => ({
            address: poolConfig.stakingToken.address,
            name: 'balanceOf',
            params: [getAddress(poolConfig.contractAddress)],
        }));
    const callsBnbPools = bnbPool.map((poolConfig) => ({
            address: tokens.wbnb.address,
            name: 'balanceOf',
            params: [getAddress(poolConfig.contractAddress)],
        }));
    const nonBnbPoolsTotalStaked = await multicall(udeABI, callsNonBnbPools);
    const bnbPoolsTotalStaked = await multicall(wbnbABI, callsBnbPools);
    return [
        ...nonBnbPools.map((p, index) => ({
            sousId: p.sousId,
            totalStaked: new BigNumber(nonBnbPoolsTotalStaked[index]).toJSON(),
        })),
        ...bnbPool.map((p, index) => ({
            sousId: p.sousId,
            totalStaked: new BigNumber(bnbPoolsTotalStaked[index]).toJSON(),
        })),
    ];
};

export const fetchPoolWeights = async () => {
    const nonBnbPools = poolsConfig.filter((p) => p.stakingToken.symbol !== 'BNB');
    const callsNonBnbPools = nonBnbPools.map((poolConfig) => ({
        address: getMasterChefAddress(),
        name: 'poolInfo',
        params: [poolConfig.sousId],
    }));
    const nonBnbPoolsTotalStaked = await multicall(chefABI, callsNonBnbPools);
    return [
        ...nonBnbPools.map((p, index) => ({
            sousId: p.sousId,
            poolWeight: nonBnbPoolsTotalStaked[index].allocPoint,
        }))
    ]
}
// export const fetchPoolStakingLimit = async (sousId) => {
//     try {
//         const sousContract = getSouschefV2Contract(sousId);
//         const stakingLimit = await sousContract.poolLimitPerUser();
//         return new BigNumber(stakingLimit.toString());
//     }
//     catch (error) {
//         return BIG_ZERO;
//     }
// };
// export const fetchPoolsStakingLimits = async (poolsWithStakingLimit) => {
//     const validPools = poolsConfig
//         .filter((p) => p.stakingToken.symbol !== 'BNB' && !p.isFinished)
//         .filter((p) => !poolsWithStakingLimit.includes(p.sousId));
//     // Get the staking limit for each valid pool
//     // Note: We cannot batch the calls via multicall because V1 pools do not have "poolLimitPerUser" and will throw an error
//     const stakingLimitPromises = validPools.map((validPool) => fetchPoolStakingLimit(validPool.sousId));
//     const stakingLimits = await Promise.all(stakingLimitPromises);
//     return stakingLimits.reduce((accum, stakingLimit, index) => ({...accum, [validPools[index].sousId]: stakingLimit}), {});
// };
