import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useActiveWeb3React from "../../../hooks/useWeb3";
import { deserializeToken } from './helpers';

export default function useUserAddedTokens() {
    const { chainId } = useActiveWeb3React();
    const serializedTokensMap = useSelector(({ user: { tokens } }) => tokens);
    return useMemo(() => {
        let _a;
        if (!chainId)
            return [];
        // eslint-disable-next-line no-cond-assign
        return Object.values((_a = serializedTokensMap === null || serializedTokensMap === undefined ? undefined : serializedTokensMap[chainId]) !== null && _a !== undefined ? _a : {}).map(deserializeToken);
    }, [serializedTokensMap, chainId]);
}
