import { useRef, useState } from 'react';
// material
import {
    Button,
    Box,
    DialogTitle,
    DialogContent,
    DialogContentText, DialogActions, Dialog, Grid
} from '@material-ui/core';
import {GridCard} from "../../components/udego";
import useConnect from "../../hooks/useConnect";
import {ConnectorNames} from "../../utils/web3React";
import {connectorLocalStorageKey} from "../../contexts/Values";

export default function WalletPopover() {
    const anchorRef = useRef(null);
    const { login } = useConnect()
    // const navigate = useNavigate();
    // const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <>
            <Button
                ref={anchorRef}
                onClick={handleClickOpen}
                variant="contained"
                sx={{
                    padding: 1,
                    height: 44,
                    borderRadius: '25px'
                }}
            >
                Connect Wallet
            </Button>

            <Dialog open={open} maxWidth='md' onClose={handleClose} fullWidth>
                <DialogTitle>Connect Wallet</DialogTitle>
                <DialogContent>
                    <DialogContentText>Connect wallet using below providers</DialogContentText>

                    <Box
                        component="form"
                        noValidate
                        sx={{
                            margin: 'auto',
                            display: 'flex',
                            width: 'fit-content',
                            flexDirection: 'column'
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <GridCard
                                    title="METAMASK"
                                    description="Connect using metamask extension (on desktop) or application (on Android/iOS devices)."
                                    img="/images/metamask.png"
                                    link="https://metamask.io/"
                                    linkText="Get Metamask"
                                    hoverColor="#e4761b40"
                                    callBack={() => {
                                        login(ConnectorNames.Injected)
                                        localStorage.setItem(connectorLocalStorageKey, ConnectorNames.Injected);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <GridCard
                                    title="TRUSTWALLET"
                                    description="Connect using trustwallet application (on Android devices)."
                                    img="/images/trustwallet.png"
                                    link="https://trustwallet.com/"
                                    linkText="Get Trustwallet"
                                    hoverColor="#3375bb4a"
                                    callBack={() => {
                                        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
                                        if(isIOS){
                                            login(ConnectorNames.WalletConnect)
                                            localStorage.setItem(connectorLocalStorageKey, ConnectorNames.WalletConnect);
                                        }else{
                                            login(ConnectorNames.Injected)
                                            localStorage.setItem(connectorLocalStorageKey, ConnectorNames.Injected);
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <GridCard
                                    title="WALLET CONNECT"
                                    description="Connect using your wallet with the top providers from wallet connect list (on Android/iOS devices)"
                                    img="/images/wallet_connect.png"
                                    link="https://walletconnect.org/"
                                    linkText="Check Wallet Connect"
                                    hoverColor="#3b99fc33"
                                    callBack={() => {
                                        login(ConnectorNames.WalletConnect)
                                        localStorage.setItem(connectorLocalStorageKey, ConnectorNames.WalletConnect);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <GridCard
                                    title="BINANCE WALLET"
                                    description="Connect using binance chain extension (on desktop)"
                                    img="/images/binance.png"
                                    link="https://www.binance.com/en/wallet-direct"
                                    linkText="Get Binance Chain Wallet"
                                    hoverColor="#f3ba2f2b"
                                    callBack={() => {
                                        login(ConnectorNames.BSC)
                                        localStorage.setItem(connectorLocalStorageKey, ConnectorNames.BSC);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
