import BigNumber from 'bignumber.js';
import {BLOCKS_PER_YEAR, UDE_PER_BLOCK, UDE_PER_YEAR} from '../config/index';
import lpAprs from '../config/constants/lpAprs.json'

// import lpAprs from '../config/constants/lpAprs.json';
/**
 * Get the APR value in %
 * @param stakingTokenPrice Token price in the same quote currency
 * @param rewardTokenPrice Token price in the same quote currency
 * @param totalStaked Total amount of stakingToken in the pool
 * @param tokenPerBlock Amount of new ude allocated to the pool for each new block
 * @returns Null if the APR is NaN or infinite.
 */
export const getPoolApr = (stakingTokenPrice, rewardTokenPrice, totalStaked, tokenPerBlock) => {
    const totalRewardPricePerYear = new BigNumber(rewardTokenPrice).times(tokenPerBlock).times(BLOCKS_PER_YEAR);
    const totalStakingTokenInPool = new BigNumber(stakingTokenPrice).times(totalStaked);
    const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100);
    return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber();
};
/**
 * Get farm APR value in %
 * @param poolWeight allocationPoint / totalAllocationPoint
 * @param udePriceUsd Ude price in USD
 * @param poolLiquidityUsd Total pool liquidity in USD
 * @param farmAddress Farm Address
 * @returns Farm Apr
 */
export const getFarmApr = (poolWeight, udePriceUsd, poolLiquidityUsd, farmAddress) => {
    // let _a;
    const yearlyUdeRewardAllocation = poolWeight ? poolWeight.times(UDE_PER_YEAR) : new BigNumber(NaN)
    const udeRewardsApr = yearlyUdeRewardAllocation.times(udePriceUsd).div(poolLiquidityUsd).times(100);
    let udeRewardsAprAsNumber = null;
    if (!udeRewardsApr.isNaN() && udeRewardsApr.isFinite()) {
        udeRewardsAprAsNumber = udeRewardsApr.toNumber();
    }
    // eslint-disable-next-line no-cond-assign,no-void
    const lpRewardsApr = lpAprs[farmAddress?.toLocaleLowerCase()] ?? 0
    return { udeRewardsApr: udeRewardsAprAsNumber, lpRewardsApr };
};
export default null;