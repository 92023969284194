// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  udego: {
    home: path(ROOTS_DASHBOARD, '/home'),
    swap: path(ROOTS_DASHBOARD, '/swap'),
    pool: path(ROOTS_DASHBOARD, '/pool'),
  },
  earn: {
    referral: path(ROOTS_DASHBOARD, '/referral'),
    farming: path(ROOTS_DASHBOARD, '/farming'),
    staking: path(ROOTS_DASHBOARD, '/staking')
  },
  docs: {
    whitepaper: path(ROOTS_DASHBOARD, '/whitepaper'),
  },
  social: {
    github: path(ROOTS_DASHBOARD, '/github'),
    youtube: path(ROOTS_DASHBOARD, '/youtube'),
    medium: path(ROOTS_DASHBOARD, '/medium'),
    facebook: path(ROOTS_DASHBOARD, '/facebook'),
    bitcointalk: path(ROOTS_DASHBOARD, '/bitcointalk'),
    twitter: path(ROOTS_DASHBOARD, '/twitter'),
    telegram: path(ROOTS_DASHBOARD, '/telegram'),
    reddit: path(ROOTS_DASHBOARD, '/reddit')
  }

};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
