import { Suspense, lazy } from 'react';
import {Navigate, useRoutes, useLocation} from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import LoadingScreen from '../components/LoadingScreen';
import {BITCOIN, FACEBOOK, GITHUB, MEDIUM, REDDIT, TELEGRAM, TWITTER, WHITEPAPER, YOUTUBE} from "../contexts/Constants";
import Redirect from "../components/Redirect";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Dashboard Routes
    {
      path: '/',
      element: (
        <DashboardLayout />
      ),
      children: [
        { path: '/', element: <Navigate to="/home" replace /> },
        { path: 'home', element: <UdegoHome /> },
        { path: 'swap', element: <UdegoSwap /> },
        { path: 'pool', element: <UdegoPool /> },
        { path: 'referral', element: <UdegoReferral /> },
        { path: 'farming', element: <UdegoFarm /> },
        { path: 'staking', element: <UdegoStake /> },
        { path: 'whitepaper', element: <Redirect link={WHITEPAPER} target="_blank" /> },
        { path: 'github', element: <Redirect link={GITHUB} target="_blank" /> },
        { path: 'youtube', element: <Redirect link={YOUTUBE} target="_blank" /> },
        { path: 'bitcointalk', element: <Redirect link={BITCOIN} target="_blank" /> },
        { path: 'medium', element: <Redirect link={MEDIUM} target="_blank" /> },
        { path: 'facebook', element: <Redirect link={FACEBOOK} target="_blank" /> },
        { path: 'twitter', element: <Redirect link={TWITTER} target="_blank" /> },
        { path: 'reddit', element: <Redirect link={REDDIT} target="_blank" /> },
        { path: 'telegram', element: <Redirect link={TELEGRAM} target="_blank" /> }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/maintenance" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/maintenance" replace /> }
  ]);
}

const UdegoHome = Loadable(lazy(() => import('../pages/UdegoHome/index')));
const UdegoSwap = Loadable(lazy(() => import('../pages/UdegoSwap')));
const UdegoPool = Loadable(lazy(() => import('../pages/UdegoPool')));

// Earn Farming and Staking
const UdegoReferral = Loadable(lazy(() => import('../pages/referral')))
const UdegoFarm = Loadable(lazy(() => import('../pages/UdegoFarm')));
const UdegoStake = Loadable(lazy(() => import('../pages/UdegoStake')));
// Main
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
