import { createSlice } from '@reduxjs/toolkit';
// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: false,
    totalSupply: 0,
    symbol: '',
    name: ''
};

const slice = createSlice({
    name: 'ude',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET FARMS
        getTotalSupply(state, action) {
            state.isLoading = false;
            state.totalSupply = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export function getTotalSupply(ude) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const totalSupply = await ude.totalSupply()
            console.log(totalSupply)
            dispatch(slice.actions.getTotalSupply(totalSupply));
        } catch (error) {
            console.log(error)
            dispatch(slice.actions.hasError(error));
        }
    };
}
