import {toast} from "react-toastify";

export const PENDING_MESSAGE = 'Transaction Submitted...'
export const ERROR_MESSAGE = 'Error while handling Transaction!!'
export const SUCCESS_MESSAGE = 'Transaction Confirmed!!'

export const ERROR_PROP = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

export const SUCCESS_PROP = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

export const INFO_PROP = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

export const showError = (message) => toast.error(message, ERROR_PROP)
export const showFulfill = (message) => toast.success(message, SUCCESS_PROP)

export const showPromise = (promise, pendingMessage=PENDING_MESSAGE, errorMessage=ERROR_MESSAGE, successMessage=SUCCESS_MESSAGE) => {
    toast.promise(
        promise,
        {
            pending: pendingMessage,
            success: successMessage,
            error: errorMessage
        },
        SUCCESS_PROP
    )
}

export const shortAddress = (address) => `${address.substr(0, 4)}....${address.substr(-4)}`