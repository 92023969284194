import { serializeTokens } from '../tokens'

const serializedTokens = serializeTokens()

const farms = [
    /**
     * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
     */
    {
        pid: 0,
        lpSymbol: 'UDE',
        lpAddresses: {
            97: '',
            56: '0xb22e110E8B83e5eFe88E4aF3a52ff39A7Afd814c',
        },
        token: serializedTokens.udepos,
        quoteToken: serializedTokens.wbnb,
    },
    {
        pid: 1,
        lpSymbol: 'UDE-BNB LP',
        lpAddresses: {
            97: '',
            56: '0xB5CDfB588d7ce09b17E3122E64B09cfeA34F17Df',
        },
        token: serializedTokens.ude,
        quoteToken: serializedTokens.wbnb,
    },
    {
        pid: 2,
        lpSymbol: 'UDE-BUSD LP',
        lpAddresses: {
            97: '',
            56: '0x36e2b6b0690e130797e130C0943e1083F6765C0C',
        },
        token: serializedTokens.ude,
        quoteToken: serializedTokens.busd,
    },
    /**
     * V3 by order of release (some may be out of PID order due to multiplier boost)
     */
    // {
    //     pid: 3,
    //     lpSymbol: 'UDE-CAKE LP',
    //     lpAddresses: {
    //         97: '',
    //         56: '0x3ae85E84B280D1C691DD5B323F8336F7c4029E10',
    //     },
    //     token: serializedTokens.ude,
    //     quoteToken: serializedTokens.cake,
    // },
    // {
    //     pid: 4,
    //     lpSymbol: 'UDE-ETH LP',
    //     lpAddresses: {
    //         97: '',
    //         56: '0x4752ffb20D1DB998669eEd9f28A4AbA24c847b9A',
    //     },
    //     token: serializedTokens.ude,
    //     quoteToken: serializedTokens.eth,
    // },
    // {
    //     pid: 5,
    //     lpSymbol: 'UDE-BAKE LP',
    //     lpAddresses: {
    //         97: '',
    //         56: '0x3e70ECeA50d00e7B8f7C09dd8215e22D5B65e387',
    //     },
    //     token: serializedTokens.ude,
    //     quoteToken: serializedTokens.bake,
    //     isCommunity: true,
    // }
]

export default farms
