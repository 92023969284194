import {useCallback} from 'react';

// auth provider
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { NoBscProviderError } from '@binance-chain/bsc-connector'
import { NoEthereumProviderError, UserRejectedRequestError as UserRejectedRequestErrorInjected } from '@web3-react/injected-connector'

import {
    UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
    WalletConnectConnector,
} from '@web3-react/walletconnect-connector'
import {connectorLocalStorageKey} from "../contexts/Values";
import {setupNetwork} from "../utils/tokenHelper";
import { ConnectorNames, connectorsByName } from '../utils/web3React'
import { showError } from "../utils/pageHelpers";

const useConnect = () => {
    const { activate, deactivate } = useWeb3React()
    const login = useCallback(
        (connectorID) => {
            const connector = connectorsByName(connectorID)
            if (connector) {
                try {
                    activate(connector, async (error) => {
                        if (error instanceof UnsupportedChainIdError) {
                            const hasSetup = await setupNetwork()
                            if (hasSetup) {
                                activate(connector)
                            }
                        } else {
                            window.localStorage.removeItem(connectorLocalStorageKey)

                            if (error instanceof NoEthereumProviderError || error instanceof NoBscProviderError) {
                                showError('Provider Error - No provider was found')
                            } else if (
                                error instanceof UserRejectedRequestErrorInjected ||
                                error instanceof UserRejectedRequestErrorWalletConnect
                            ) {
                                if (connector instanceof WalletConnectConnector) {
                                    const walletConnector = connector
                                    walletConnector.walletConnectProvider = null
                                }
                                showError('Authorization Error - Please authorize to access your account')
                            } else {
                                showError(error.name, error.message)
                            }
                        }
                    })
                }catch (e) {
                    showError(e)
                }
            } else {
                showError('Unable to find connector - The connector config is wrong')
            }
        },
        [activate]
    )

    const logout = useCallback(() => {
        deactivate()
        // This localStorage key is set by @web3-react/walletconnect-connector
        if (window.localStorage.getItem('walletconnect')) {
            connectorsByName(ConnectorNames.WalletConnect).close()
            connectorsByName(ConnectorNames.WalletConnect).walletConnectProvider = null
        }
        window.localStorage.removeItem(connectorLocalStorageKey)
    }, [deactivate])

    return { login, logout }
};

export default useConnect;
