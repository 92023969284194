import { useRef, useState } from 'react';
// material
import {
    Button,
    DialogTitle,
    DialogContent,
    DialogContentText, DialogActions, Dialog,
    List, ListItem, ListItemAvatar, Avatar, ListItemText,
    Typography, Grid, TextField, IconButton
} from '@material-ui/core';
import {useWeb3React} from "@web3-react/core";
import {QRCode} from "react-qrcode-logo";
import {
    CheckCircleRounded,
    CloseOutlined,
    OpenInNewRounded,
    QrCodeScannerRounded,
} from "@material-ui/icons";
import QrReader from 'react-qr-scanner'

import useConnect from "../../hooks/useConnect";
import {shortAddress} from "../../utils/pageHelpers";
import {appConstants, EXPLORER} from "../../config/constants";
import {ALL_TOKENS} from "../../contexts/TokenList";

export default function UdegoProfile() {
    const anchorRef = useRef(null);
    const { account } = useWeb3React()
    const { logout } = useConnect()
    const [openScan, setScan] = useState(false);
    const [recipentAddress, setRecipent] = useState("");
    const [scanError, setScanError] = useState(null);
    // const navigate = useNavigate();
    // const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleScan = (data) => {
        console.log(data)
        setScanError(null)
        if(data){
            setRecipent(data.text)
            toggleScan()
        }
    }
    const handleError = (err) => {
        setScanError(err.toString())
        console.log(err)
    }

    const toggleScan = () => {
        setScan(!openScan)
    }

    const checkLoad = (val) => {
        console.log(val)
    }

    const inputRecipent = (val) => {
        setRecipent(val.target.value)
    }

    return (
        <>
            <Button
                ref={anchorRef}
                onClick={handleClickOpen}
                variant="contained"
                sx={{
                    padding: 1,
                    height: 44,
                    borderRadius: '25px'
                }}
            >
                {shortAddress(account)}
            </Button>

            <Dialog open={open} maxWidth='md' onClose={handleClose} fullWidth>
                <DialogTitle>
                    <a href={`${EXPLORER}address/${account}/`} target="_blank" rel="noreferrer">{shortAddress(account)} <OpenInNewRounded /></a> Profile
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <b>Explore UDEGO Profile!</b><br />View recent transactions, Quick tools for UDE Tokens, and many more.. </DialogContentText>
                    <Grid container spacing={2} sx={{textAlign:'center'}}>
                        <Grid item xs={12} lg={12} md={12}>
                            <h2>Receive UDE (or Any Token)</h2>
                            <QRCode size={240} logoImage={`/tokens/${appConstants.symbol.toLowerCase()}.png`} value={account} />
                        </Grid>
                        {/* <Grid item xs={12} lg={6} md={6} sx={{display: 'flex', alignItems:'center'}}> */}
                        {/*    <div> */}
                        {/*        <h2>Send UDE</h2> */}
                        {/*        <Grid container spacing={1}> */}
                        {/*            <Grid item xs={12} md={12}> */}
                        {/*                { */}
                        {/*                    openScan && <QrReader */}
                        {/*                        onLoad={checkLoad} */}
                        {/*                        facingMode='back' */}
                        {/*                        delay={500} */}
                        {/*                        style={{ */}
                        {/*                            height: 240, */}
                        {/*                            width: 320, */}
                        {/*                        }} */}
                        {/*                        onError={handleError} */}
                        {/*                        onScan={handleScan} */}
                        {/*                    /> */}
                        {/*                } */}
                        {/*            </Grid> */}
                        {/*            { */}
                        {/*                (openScan && scanError) && */}
                        {/*                <Grid item xs={12} md={12}> */}
                        {/*                    {scanError} */}
                        {/*                </Grid> */}
                        {/*            } */}
                        {/*            <Grid item xs={12} md={12}> */}
                        {/*                { */}
                        {/*                    !openScan && <Button variant="contained" onClick={toggleScan}> */}
                        {/*                        Scan <QrCodeScannerRounded /> */}
                        {/*                    </Button> */}
                        {/*                } */}

                        {/*                { */}
                        {/*                    openScan && <Button variant="contained" onClick={toggleScan} color="error"> */}
                        {/*                        Close <CloseOutlined /> */}
                        {/*                    </Button> */}
                        {/*                } */}
                        {/*            </Grid> */}
                        {/*            <Grid item xs={12} md={12}> */}
                        {/*                <TextField value={recipentAddress} onChange={inputRecipent} type="text" fullWidth variant="filled" label={`Enter ${appConstants.symbol} Address`} placeholder="Receiver Address" title={`Enter ${appConstants.symbol} Address`} /> */}
                        {/*            </Grid> */}
                        {/*            <Grid item xs={12} md={12}> */}
                        {/*                <TextField type="number" fullWidth variant="filled" label={`Enter ${appConstants.symbol} Amount`} placeholder={`${appConstants.symbol} Amount to send`} title={`Enter ${appConstants.symbol} Amount`} /> */}
                        {/*            </Grid> */}
                        {/*            <Grid item xs={12} md={12}> */}
                        {/*                <Button variant="outlined" fullWidth> */}
                        {/*                    Quick Send */}
                        {/*                </Button> */}
                        {/*            </Grid> */}
                        {/*        </Grid> */}
                        {/*    </div> */}
                        {/* </Grid> */}
                    </Grid>
                    <div>
                        {/* <Grid container> */}
                            {/* <Grid item xs={12} lg={6} md={6}> */}
                            {/*    <h4>Your Balances</h4> */}
                            {/*    <List> */}
                            {/*        <ListItem */}
                            {/*            button */}
                            {/*            secondaryAction={ */}
                            {/*                <Typography> */}
                            {/*                    0 */}
                            {/*                </Typography> */}
                            {/*            } */}
                            {/*        > */}
                            {/*            <ListItemAvatar> */}
                            {/*                <Avatar src="/tokens/bnb.png" /> */}
                            {/*            </ListItemAvatar> */}
                            {/*            <ListItemText */}
                            {/*                primary="BNB Balance" */}
                            {/*                secondary="BNB Balance in your wallet" */}
                            {/*            /> */}
                            {/*        </ListItem> */}
                            {/*        <ListItem */}
                            {/*            button */}
                            {/*            secondaryAction={ */}
                            {/*                <Typography> */}
                            {/*                    0 */}
                            {/*                </Typography> */}
                            {/*            } */}
                            {/*        > */}
                            {/*            <ListItemAvatar> */}
                            {/*                <Avatar src="/tokens/ude.png" /> */}
                            {/*            </ListItemAvatar> */}
                            {/*            <ListItemText */}
                            {/*                primary={`${appConstants.symbol} Balance`} */}
                            {/*                secondary={`${appConstants.symbol} Balance in your wallet`} */}
                            {/*            /> */}
                            {/*        </ListItem> */}
                            {/*    </List> */}
                            {/* </Grid> */}
                        {/*    <Grid item xs={12} lg={6} md={6}> */}
                        {/*        <h4>UDE LP Balances</h4> */}
                        {/*        <List> */}
                        {/*            <ListItem */}
                        {/*                button */}
                        {/*                secondaryAction={ */}
                        {/*                    <Typography> */}
                        {/*                        0 */}
                        {/*                    </Typography> */}
                        {/*                } */}
                        {/*            > */}
                        {/*                <ListItemAvatar> */}
                        {/*                    <div style={{display: 'flex'}}> */}
                        {/*                        <Avatar src="/tokens/bnb.png" /> */}
                        {/*                        <Avatar src={`/tokens/${appConstants.symbol.toLowerCase()}.png`} sx={{marginLeft: '-20px', backgroundColor: 'white'}} /> */}
                        {/*                    </div> */}
                        {/*                </ListItemAvatar> */}
                        {/*                <ListItemText */}
                        {/*                    primary={`${appConstants.symbol.toUpperCase()}/BNB LP Balance`} */}
                        {/*                    secondary="Liquidity Pool Tokens" */}
                        {/*                /> */}
                        {/*            </ListItem> */}
                        {/*            <ListItem */}
                        {/*                button */}
                        {/*                secondaryAction={ */}
                        {/*                    <Typography> */}
                        {/*                        0 */}
                        {/*                    </Typography> */}
                        {/*                } */}
                        {/*            > */}
                        {/*                <ListItemAvatar> */}
                        {/*                    <div style={{display: 'flex'}}> */}
                        {/*                        <Avatar src={`/tokens/${ALL_TOKENS.busd}.png`} /> */}
                        {/*                        <Avatar src={`/tokens/${appConstants.symbol.toLowerCase()}.png`} sx={{marginLeft: '-20px', backgroundColor: 'white'}} /> */}
                        {/*                    </div> */}
                        {/*                </ListItemAvatar> */}
                        {/*                <ListItemText */}
                        {/*                    primary={`${appConstants.symbol.toUpperCase()}/BUSD LP Balance`} */}
                        {/*                    secondary="Liquidity Pool Tokens" */}
                        {/*                /> */}
                        {/*            </ListItem> */}
                        {/*            <ListItem */}
                        {/*                button */}
                        {/*                secondaryAction={ */}
                        {/*                    <Typography> */}
                        {/*                        0 */}
                        {/*                    </Typography> */}
                        {/*                } */}
                        {/*            > */}
                        {/*                <ListItemAvatar> */}
                        {/*                    <div style={{display: 'flex'}}> */}
                        {/*                        <Avatar src={`/tokens/${ALL_TOKENS.cake}.png`} /> */}
                        {/*                        <Avatar src={`/tokens/${appConstants.symbol.toLowerCase()}.png`} sx={{marginLeft: '-20px', backgroundColor: 'white'}} /> */}
                        {/*                    </div> */}
                        {/*                </ListItemAvatar> */}
                        {/*                <ListItemText */}
                        {/*                    primary={`${appConstants.symbol.toUpperCase()}/CAKE LP Balance`} */}
                        {/*                    secondary="Liquidity Pool Tokens" */}
                        {/*                /> */}
                        {/*            </ListItem> */}
                        {/*        </List> */}
                        {/*    </Grid> */}
                        {/* </Grid> */}
                        {/* <hr /> */}
                        {/* <h2>Recent Transactions</h2> */}

                        {/* <List> */}
                        {/*    <ListItem */}
                        {/*        secondaryAction={ */}
                        {/*            <IconButton> */}
                        {/*                <OpenInNewRounded /> */}
                        {/*            </IconButton> */}
                        {/*        } */}
                        {/*    > */}
                        {/*        <ListItemAvatar> */}
                        {/*            <CheckCircleRounded color="success" /> */}
                        {/*        </ListItemAvatar> */}
                        {/*        <ListItemText */}
                        {/*            primary="SWAP" */}
                        {/*            secondary="Swapped 0.1 BNB For 100 UDE" */}
                        {/*        /> */}
                        {/*    </ListItem> */}

                        {/*    <ListItem */}
                        {/*        secondaryAction={ */}
                        {/*            <IconButton> */}
                        {/*                <OpenInNewRounded /> */}
                        {/*            </IconButton> */}
                        {/*        } */}
                        {/*    > */}
                        {/*        <ListItemAvatar> */}
                        {/*            <CheckCircleRounded color="success" /> */}
                        {/*        </ListItemAvatar> */}
                        {/*        <ListItemText */}
                        {/*            primary="POOL" */}
                        {/*            secondary="Added 0.1 BNB and 100 UDE" */}
                        {/*        /> */}
                        {/*    </ListItem> */}

                        {/*    <ListItem */}
                        {/*        secondaryAction={ */}
                        {/*            <IconButton> */}
                        {/*                <OpenInNewRounded /> */}
                        {/*            </IconButton> */}
                        {/*        } */}
                        {/*    > */}
                        {/*        <ListItemAvatar> */}
                        {/*            <CheckCircleRounded color="success" /> */}
                        {/*        </ListItemAvatar> */}
                        {/*        <ListItemText */}
                        {/*            primary="FARMING" */}
                        {/*            secondary="Harvested 12.32 UDE from Farming" */}
                        {/*        /> */}
                        {/*    </ListItem> */}

                        {/*    <ListItem */}
                        {/*        secondaryAction={ */}
                        {/*            <IconButton> */}
                        {/*                <OpenInNewRounded /> */}
                        {/*            </IconButton> */}
                        {/*        } */}
                        {/*    > */}
                        {/*        <ListItemAvatar> */}
                        {/*            <CheckCircleRounded color="success" /> */}
                        {/*        </ListItemAvatar> */}
                        {/*        <ListItemText */}
                        {/*            primary="STAKING" */}
                        {/*            secondary="Staked 2423.32 UDE in UDE Pool" */}
                        {/*        /> */}
                        {/*    </ListItem> */}
                        {/* </List> */}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={logout} color="error" variant="contained">
                        Logout
                    </Button>
                    <Button onClick={handleClose} variant="contained">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
