// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// hooks
import useAuth from './hooks/useAuth';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';
import {getLibrary} from "./utils/web3React";
import {usePollBlockNumber} from "./state/block/hooks";
import useEagerConnect from "./hooks/useEagerConnect";
import useUserAgent from "./hooks/useUserAgent";
import {usePollCoreFarmData} from "./state/farms/hooks";

// ----------------------------------------------------------------------

export default function App() {
    const { isInitialized } = useAuth();
    usePollBlockNumber()
    useEagerConnect()
    useUserAgent()
    usePollCoreFarmData()

    return (
        <ThemeConfig>
            <ThemePrimaryColor>
                <ThemeLocalization>
                  <RtlLayout>
                    <NotistackProvider>
                      <Settings />
                      <ScrollToTop />
                      <GoogleAnalytics />
                      {isInitialized ? <Router /> : <LoadingScreen />}
                    </NotistackProvider>
                  </RtlLayout>
                </ThemeLocalization>
            </ThemePrimaryColor>
        </ThemeConfig>
    );
}
