export const PAGE_TITLE = 'Udego Finance - Decentralized Finance on Binance Smart Chain'
export const APP_NAME = 'Udego Finance'
export const TOKEN_NAME = 'UDEGO'
export const TOKEN_SYMBOL = 'UDE'

export const WHITEPAPER = 'https://udegofinance.gitbook.io/udego-finance/'
export const GITHUB = 'https://github.com/UdegoFinance'
export const TWITTER = 'https://twitter.com/UdegoFinance'
export const YOUTUBE = 'https://www.youtube.com/channel/UCfQen7asIoWe_GLDtz_hQ7w'
export const FACEBOOK = 'https://www.facebook.com/UdegoFinance'
export const BITCOIN = 'https://bitcointalk.org/index.php?action=profile;u=3378345'
export const MEDIUM = 'https://medium.com/@udegofinance'
export const TELEGRAM = 'https://t.me/UdegoFinance'
export const REDDIT = 'https://www.reddit.com/r/Udegofinance'

export default PAGE_TITLE