// eslint-disable-next-line vars-on-top,import/no-mutable-exports,no-var
export const  PoolIds = {
    poolBasic: "poolBasic",
    poolUnlimited: "poolUnlimited",
};
// eslint-disable-next-line vars-on-top,import/no-mutable-exports,no-var
export const PoolCategory = {
    COMMUNITY: "Community",
    CORE: "Core",
    BINANCE: "Binance",
    AUTO: "Auto"
};
// eslint-disable-next-line vars-on-top,import/no-mutable-exports,no-var
export const LotteryStatus = {
    PENDING: "pending",
    OPEN: "open",
    CLOSE: "close",
    CLAIMABLE: "claimable"
};
// Note: this status is slightly different compared to 'status' comfing
// from Farm Auction smart contract
// eslint-disable-next-line vars-on-top,import/no-mutable-exports,no-var
export const AuctionStatus = () => {
    AuctionStatus[AuctionStatus.ToBeAnnounced = 0] = "ToBeAnnounced";
    AuctionStatus[AuctionStatus.Pending = 1] = "Pending";
    AuctionStatus[AuctionStatus.Open = 2] = "Open";
    AuctionStatus[AuctionStatus.Finished = 3] = "Finished";
    AuctionStatus[AuctionStatus.Closed = 4] = "Closed";
};
