import { serializeTokens } from '../tokens';
import { PoolCategory } from './types';

const serializedTokens = serializeTokens();
const pools = [
    {
        sousId: 0,
        stakingToken: serializedTokens.ude,
        earningToken: serializedTokens.ude,
        contractAddress: {
            97: '',
            56: '0x78cea70f1d0d92a18d3dc9230c32c67afaa38977',
        },
        poolCategory: PoolCategory.CORE,
        harvest: true,
        tokenPerBlock: '0.38',
        sortOrder: 1,
        isFinished: false,
    },
    {
        sousId: 6,
        stakingToken: serializedTokens.wbnb,
        earningToken: serializedTokens.ude,
        contractAddress: {
            97: '',
            56: '0x78cea70f1d0d92a18d3dc9230c32c67afaa38977',
        },
        poolCategory: PoolCategory.CORE,
        harvest: true,
        sortOrder: 999,
        tokenPerBlock: '0.01',
    },
    {
        sousId: 7,
        stakingToken: serializedTokens.cake,
        earningToken: serializedTokens.ude,
        contractAddress: {
            97: '',
            56: '0x78cea70f1d0d92a18d3dc9230c32c67afaa38977',
        },
        poolCategory: PoolCategory.CORE,
        harvest: true,
        sortOrder: 999,
        tokenPerBlock: '0.01',
    }
];
export default pools;