export const config = {
    TOKEN_NAME: "UDEGO",
    TOKEN_SYMBOL: "UDE",
}

export const rpcUrl = {
    '0x89' : "https://rpc-mainnet.maticvigil.com/",
    '0x13881' : "https://rpc-mainnet.maticvigil.com/",
    '0x38' : "https://bsc-dataseed.binance.org/",
    '0x61' : 'https://data-seed-prebsc-2-s1.binance.org:8545/'
}

export const PC_ROUTER_ADDRESS = '0x10ED43C718714eb63d5aA57B78B54704E256024E';
export const ROUTER_ADDRESS = '0x10ED43C718714eb63d5aA57B78B54704E256024E'
export const UDE_ROUTER = '0x5c2E80Ab34D0e7Bc72802a743677068C7C0D4701'

export const DEFAULT_NETWORK = '0x38'
export const WALLET_ADDRESS = 'wallet_address'
export const TRANSACTIONS   = 'transactions'
export const REFER_LINK = 'https://dapp.udego.finance/home?referLink='

export const ERROR_PROP = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

export const SUCCESS_PROP = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

export const INFO_PROP = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

export const POOLS_URL = "http://localhost:8082/"
export const MIN_PRICE_IMPACT = 0.0001
export const SWAP_IN = 0
export const SWAP_OUT = 1

export const PENDING_MESSAGE = 'Transaction Submitted...'
export const ERROR_MESSAGE = 'Error while handling Transaction!!'
export const SUCCESS_MESSAGE = 'Transaction Confirmed!!'
export const ISWC = "IS_WALLET_CONNECT"
export const WCHAIN = 'WALLET_CONNECT_CHAIN_ID'

export const BASE_URL = 'https://dapp.udego.finance/'
export const SWAP_SOUND = './swap.mp3'