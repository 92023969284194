import { Token } from '@pancakeswap/sdk';
import { parseUnits } from 'ethers/lib/utils';

export function serializeToken(token) {
    return {
        chainId: token.chainId,
        address: token.address,
        decimals: token.decimals,
        symbol: token.symbol,
        name: token.name,
        projectLink: token.projectLink,
    };
}
export function deserializeToken(serializedToken) {
    return new Token(serializedToken.chainId, serializedToken.address, serializedToken.decimals, serializedToken.symbol, serializedToken.name, serializedToken.projectLink);
}

// eslint-disable-next-line import/no-mutable-exports,vars-on-top,no-var
export const GAS_PRICE = {
    default: "5",
    fast: "6",
    instant: "7",
    testnet: "10"
}

export const GAS_PRICE_GWEI = {
    default: parseUnits(GAS_PRICE.default, 'gwei').toString(),
    fast: parseUnits(GAS_PRICE.fast, 'gwei').toString(),
    instant: parseUnits(GAS_PRICE.instant, 'gwei').toString(),
    testnet: parseUnits(GAS_PRICE.testnet, 'gwei').toString(),
};