// material
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

export default function BannerUdego({ ...other }) {
    return (
        <Box component="img" {...other} src="/images/banner.png" />
    );
}
