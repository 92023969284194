export default function uriToHttp(uri) {
    let _a; let _b;
    const protocol = uri.split(':')[0].toLowerCase();
    switch (protocol) {
        case 'https':
            return [uri];
        case 'http':
            return [`https${uri.substr(4)}`, uri];
        case 'ipfs':
            // eslint-disable-next-line no-cond-assign,no-case-declarations
            const hash = (_a = uri.match(/^ipfs:(\/\/)?(.*)$/i)) === null || _a === undefined ? undefined : _a[2];
            return [`https://cloudflare-ipfs.com/ipfs/${hash}/`, `https://ipfs.io/ipfs/${hash}/`];
        case 'ipns':
            // eslint-disable-next-line no-cond-assign,no-case-declarations
            const name = (_b = uri.match(/^ipns:(\/\/)?(.*)$/i)) === null || _b === undefined ? undefined : _b[2];
            return [`https://cloudflare-ipfs.com/ipns/${name}/`, `https://ipfs.io/ipns/${name}/`];
        default:
            return [];
    }
}
