import PropTypes from 'prop-types';
import {Container, Link, Paper, Stack, Typography} from "@material-ui/core";
import {motion} from "framer-motion";
import {Icon} from "@iconify/react";
import roundArrowRightAlt from "@iconify/icons-ic/round-arrow-right-alt";
import {useTheme} from "@material-ui/core/styles";
import {varFadeInUp} from "../animate";

const GridCard = (
    {
        title="",
        img="",
        description="",
        link="",
        linkText="",
        hoverColor="",
        callBack=null
    }) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    return (
        <Paper
            onClick={callBack}
            sx={{
            py: 3,
            textAlign: 'center',
            boxShadow: (theme) => theme.customShadows.z8,
            height: "240px",
            cursor:'pointer',
            '&:hover':{
                background: 'none !important',
                bgcolor: `${hoverColor} !important`
            }
        }}
               style={{
                   background: `linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url('${img}')`,
                   backgroundRepeat: 'no-repeat',
                   backgroundSize: '50%',
                   backgroundPosition: 'center',
               }}
        >
            <Container
                maxWidth="lg"
                sx={{
                    display: 'flex',
                    justifyContent:'space-between'
                }}
            >
                <div>
                    <motion.div variants={varFadeInUp}>
                        <h3>
                            {title}
                        </h3>
                    </motion.div>
                    <motion.div variants={varFadeInUp}>
                        <Typography
                            sx={{
                                mt: 3,
                                mb: 5,
                                color: isLight ? 'text.secondary' : 'common.white'
                            }}
                        >
                            {description}
                        </Typography>
                    </motion.div>
                    <motion.div variants={varFadeInUp}>
                        <Link href={link} target="_blank">
                            <Stack direction="row" spacing={1} alignItems="center" sx={{ display: 'inline-flex' }}>
                                <Typography variant="button">{linkText}</Typography>
                                <Icon icon={roundArrowRightAlt} width={20} height={20} />
                            </Stack>
                        </Link>
                    </motion.div>
                </div>
            </Container>
        </Paper>
    )
}


GridCard.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    img: PropTypes.string,
    link: PropTypes.string,
    linkText: PropTypes.string,
    hoverColor: PropTypes.string,
    callBack: PropTypes.func
}
export default GridCard;