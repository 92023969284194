import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: false,
    myProfile: null,
    posts: [],
    farms: [],
    userList: [],
    followers: [],
    friends: [],
    gallery: [],
    cards: null,
    addressBook: [],
    invoices: [],
    notifications: null
};

const slice = createSlice({
    name: 'farm',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET FARMS
        getFarmsSuccess(state, action) {
            console.log(action.payload)
            console.log(state.farms)
            state.isLoading = false;
            state.farms = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow, deleteUser } = slice.actions;

export function getFarms() {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/data/allFarms.json', {
                responseType: "json"
            });
            const farms = response.data
            dispatch(slice.actions.getFarmsSuccess(farms));
        } catch (error) {
            console.log(error)
            dispatch(slice.actions.hasError(error));
        }
    };
}
