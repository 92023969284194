import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text, Flex, Link } from '@pancakeswap/uikit';

import useToast from '../../hooks/useToast';
import { getBscScanLink } from '../../utils';
import useActiveWeb3React from "../../hooks/useWeb3";
import { useBlock } from "../block/hooks";
import { checkedTransaction, finalizeTransaction } from './actions';

export function shouldCheck(currentBlock, tx) {
    if (tx.receipt)
        return false;
    if (!tx.lastCheckedBlockNumber)
        return true;
    const blocksSinceCheck = currentBlock - tx.lastCheckedBlockNumber;
    if (blocksSinceCheck < 1)
        return false;
    const minutesPending = (new Date().getTime() - tx.addedTime) / 1000 / 60;
    if (minutesPending > 60) {
        // every 10 blocks if pending for longer than an hour
        return blocksSinceCheck > 9;
    }
    if (minutesPending > 5) {
        // every 3 blocks if pending more than 5 minutes
        return blocksSinceCheck > 2;
    }
    // otherwise every block
    return true;
}
export default function Updater() {
    const { library, chainId } = useActiveWeb3React();

    const { currentBlock } = useBlock();
    const dispatch = useDispatch();
    const state = useSelector((s) => s.transactions);
    // eslint-disable-next-line no-nested-ternary,no-cond-assign,no-void
    const transactions = useMemo(() => { let _a; return (chainId ? (_a = state[chainId]) !== null && _a !== void 0 ? _a : {} : {}); }, [chainId, state]);
    const { toastError, toastSuccess } = useToast();
    useEffect(() => {
        if (!chainId || !library || !currentBlock)
            return;
        Object.keys(transactions)
            .filter((hash) => shouldCheck(currentBlock, transactions[hash]))
            .forEach((hash) => {
            library
                .getTransactionReceipt(hash)
                .then((receipt) => {
                let _a; let _b;
                if (receipt) {
                    dispatch(finalizeTransaction({
                        chainId,
                        hash,
                        receipt: {
                            blockHash: receipt.blockHash,
                            blockNumber: receipt.blockNumber,
                            contractAddress: receipt.contractAddress,
                            from: receipt.from,
                            status: receipt.status,
                            to: receipt.to,
                            transactionHash: receipt.transactionHash,
                            transactionIndex: receipt.transactionIndex,
                        },
                    }));
                    const toast = receipt.status === 1 ? toastSuccess : toastError;
                    toast('Transaction receipt', React.createElement(Flex, { flexDirection: "column" },
                        // eslint-disable-next-line no-cond-assign,no-void
                        React.createElement(Text, null, (_b = (_a = transactions[hash]) === null || _a === void 0 ? void 0 : _a.summary) !== null && _b !== void 0 ? _b : `Hash: ${hash.slice(0, 8)}...${hash.slice(58, 65)}`),
                        chainId && (React.createElement(Link, { external: true, href: getBscScanLink(hash, 'transaction', chainId) }, 'View on BscScan'))));
                }
                else {
                    dispatch(checkedTransaction({ chainId, hash, blockNumber: currentBlock }));
                }
            })
                .catch((error) => {
                console.error(`failed to check transaction hash: ${hash}`, error);
            });
        });
    }, [chainId, library, transactions, currentBlock, dispatch, toastSuccess, toastError]);
    return null;
}