import {
  AttachMoneyOutlined,
  DocumentScanner,
  GitHub,
  MonetizationOnRounded, QueryBuilderRounded,
  Reddit,
  Telegram,
  YouTube
} from "@material-ui/icons";
import {Typography} from "@material-ui/core";
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'udego',
    items: [
      {
        title: 'home',
        path: PATH_DASHBOARD.udego.home,
        icon: ICONS.dashboard
      },
      { title: 'swap', path: PATH_DASHBOARD.udego.swap, icon: ICONS.ecommerce },
      { title: 'pool', path: PATH_DASHBOARD.udego.pool, icon: ICONS.analytics }
    ]
  },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'earn',
    items: [
      {
        title: 'referral',
        path: PATH_DASHBOARD.earn.referral,
        icon: <AttachMoneyOutlined />
      },
      {
        title: 'farming',
        path: PATH_DASHBOARD.earn.farming,
        icon: <MonetizationOnRounded />
      },
      { title: 'staking',
        path: PATH_DASHBOARD.earn.staking,
        icon: <QueryBuilderRounded />
      }
    ]
  },
  {
    subheader: 'docs',
    items: [
      {
        title: 'whitepaper',
        path: PATH_DASHBOARD.docs.whitepaper,
        external: true,
        icon: <DocumentScanner />
      }
    ]
  },
  {
    subheader: 'social profiles',
    items: [
      {
        title: 'github',
        path: PATH_DASHBOARD.social.github,
        icon: <GitHub />
      },
      { title: 'telegram',
        path: PATH_DASHBOARD.social.telegram,
        icon: <Telegram />
      },
      { title: 'medium',
        path: PATH_DASHBOARD.social.medium,
        icon: <Typography>M</Typography>
      },
      { title: 'youtube',
        path: PATH_DASHBOARD.social.youtube,
        icon: <YouTube />
      },
      { title: 'reddit',
        path: PATH_DASHBOARD.social.reddit,
        icon: <Reddit />
      }
    ]
  }
];

export default sidebarConfig;
