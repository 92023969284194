import { createAction } from '@reduxjs/toolkit';

// eslint-disable-next-line import/no-mutable-exports,no-var
export const Field = () => {
    Field.LIQUIDITY_PERCENT = "LIQUIDITY_PERCENT";
    Field.LIQUIDITY = "LIQUIDITY";
    Field.CURRENCY_A = "CURRENCY_A";
    Field.CURRENCY_B = "CURRENCY_B";
};
export const typeInput = createAction('burn/typeInputBurn');
