import {ALL_TOKENS} from "../contexts/TokenList";

export const appConstants = {
    symbol: 'UDE',
    name: 'UDEGO',
    token: ALL_TOKENS.ude
}

export const WALLET_CONNECTED = 'walletConnected'
export const EXPLORER = 'https://bscscan.com/'
export const INITIAL_ALLOWED_SLIPPAGE = 50
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20
export const FARMING = '0x78cea70f1d0d92a18d3dc9230c32c67afaa38977'

