import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@material-ui/core';
// hooks
import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import { MHidden } from '../../components/@material-extend';
import WalletPopover from "./WalletPopover";
import UdegoProfile from "./UdegoProfile";
import {initReferralCode} from "../../config/helpers";
import useActiveWeb3React from "../../hooks/useWeb3";


// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

const useQuery= () => new URLSearchParams(useLocation().search);

export default function DashboardNavbar({ onOpenSidebar }) {
  const { isCollapse } = useCollapseDrawer();
  const { account } = useActiveWeb3React()

  const query = useQuery()
  const referrer = query.get("referLink")
  // useEffect(() => {
  //   if(referrer != null) {
  //     try{
  //       console.log(referrer)
  //       if(account && referrer && referrer.toLowerCase() !== account.toLowerCase()){
  //         initReferralCode(referrer, account)
  //       }
  //     }catch (e) {
  //       console.log(e)
  //     }
  //   }
  // }, [referrer, account])

  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` }
        })
      }}
    >
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {
            account && <UdegoProfile />
          }
          {
            !account && <WalletPopover />
          }
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
