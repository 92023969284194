import BigNumber from 'bignumber.js';
import { deserializeToken } from "../user/hooks/helpers";
import { BIG_ZERO } from '../../utils/bigNumber';
import {getTokenPrice} from "../../config/helpers";

export const transformUserData = (userData) => ({
        allowance: userData ? new BigNumber(userData.allowance) : BIG_ZERO,
        stakingTokenBalance: userData ? new BigNumber(userData.stakingTokenBalance) : BIG_ZERO,
        stakedBalance: userData ? new BigNumber(userData.stakedBalance) : BIG_ZERO,
        pendingReward: userData ? new BigNumber(userData.pendingReward) : BIG_ZERO,
        userLast: userData ? userData.userLast : BIG_ZERO,
    });
export const transformPool = (pool) => {
    const { totalStaked, stakingLimit, userData, stakingToken, earningToken, ...rest } = pool
    return {
        ...rest,
        stakingToken: deserializeToken(stakingToken),
        earningToken: deserializeToken(earningToken),
        userData: transformUserData(userData),
        totalStaked: new BigNumber(totalStaked),
        stakingLimit: new BigNumber(stakingLimit),
    }
};
export const getTokenPricesFromFarm = async (farms) =>
{
    const prices = {}
    // eslint-disable-next-line no-plusplus
    for(let i=0; i<farms.length; i++){
        const farm = farms[i]
        const quoteTokenAddress = farm.quoteToken.address.toLocaleLowerCase();
        const tokenAddress = farm.token.address.toLocaleLowerCase();
        /* eslint-disable no-param-reassign */
        if (!prices[quoteTokenAddress]) {
            // eslint-disable-next-line no-await-in-loop
            prices[quoteTokenAddress] = await getTokenPrice(quoteTokenAddress);
        }
        if (!prices[tokenAddress]) {
            // eslint-disable-next-line no-await-in-loop
            prices[tokenAddress] = await getTokenPrice(tokenAddress);
        }
    }
    return prices
}
