import contenthashToUri from './contenthashToUri';
import { parseENSAddress } from './ENS/parseENSAddress';
import uriToHttp from './uriToHttp';

// const tokenListValidator = new Ajv({ allErrors: true }).compile(schema);
/**
 * Contains the logic for resolving a list URL to a validated token list
 * @param listUrl list url
 * @param resolveENSContentHash resolves an ens name to a contenthash
 */
export default async function getTokenList(listUrl, resolveENSContentHash) {
    let _a;
    const parsedENS = parseENSAddress(listUrl);
    let urls;
    if (parsedENS) {
        let contentHashUri;
        try {
            contentHashUri = await resolveENSContentHash(parsedENS.ensName);
        }
        catch (error) {
            console.error(`Failed to resolve ENS name: ${parsedENS.ensName}`, error);
            throw new Error(`Failed to resolve ENS name: ${parsedENS.ensName}`);
        }
        let translatedUri;
        try {
            translatedUri = contenthashToUri(contentHashUri);
        }
        catch (error) {
            console.error('Failed to translate contenthash to URI', contentHashUri);
            throw new Error(`Failed to translate contenthash to URI: ${contentHashUri}`);
        }
        // eslint-disable-next-line no-cond-assign
        urls = uriToHttp(`${translatedUri}${(_a = parsedENS.ensPath) !== null && _a !== undefined ? _a : ''}`);
    }
    else {
        urls = uriToHttp(listUrl);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < urls.length; i++) {
        const url = urls[i];
        const isLast = i === urls.length - 1;
        let response;
        try {
            // eslint-disable-next-line no-await-in-loop
            response = await fetch(url);
        }
        catch (error) {
            console.error('Failed to fetch list', listUrl, error);
            if (isLast)
                throw new Error(`Failed to download list ${listUrl}`);
            // eslint-disable-next-line no-continue
            continue;
        }
        if (!response.ok) {
            if (isLast)
                throw new Error(`Failed to download list ${listUrl}`);
            // eslint-disable-next-line no-continue
            continue;
        }
        // eslint-disable-next-line no-await-in-loop
        const json = await response.json();
        // if (!tokenListValidator(json)) {
        //     // eslint-disable-next-line no-cond-assign
        //     const validationErrors = (_c = (_b = tokenListValidator.errors) === null || _b === undefined ? undefined : _b.reduce((memo, error) => {
        //         let _a;
        //         // eslint-disable-next-line no-cond-assign
        //         const add = `${error.dataPath} ${(_a = error.message) !== null && _a !== undefined ? _a : ''}`;
        //         return memo.length > 0 ? `${memo}; ${add}` : `${add}`;
        //     }, '')) !== null && _c !== undefined ? _c : 'unknown error';
        //     throw new Error(`Token list failed validation: ${validationErrors}`);
        // }
        return json;
    }
    throw new Error('Unrecognized list URL protocol.');
}
