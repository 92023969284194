import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { save, load } from 'redux-localstorage-simple';

// slices
import {getDefaultMiddleware} from "@reduxjs/toolkit";
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import blogReducer from './slices/blog';
import userReducer from './slices/user';
import farmReducer from './slices/farms';
import poolReducer from './slices/pools';
import tokenReducer from './slices/tokens';
import udeReducer from './slices/ude';
import walletReducer from './slices/wallet';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';

import farmsReducer from '../state/farms';
import poolsReducer from '../state/pools';
import blockReducer from '../state/block';
import user from '../state/user/reducer';
import transactions from '../state/transactions/reducer';
import swap from '../state/swap/reducer';
import lists from '../state/lists/reducer';
import burn from '../state/burn/reducer';
import multicall from '../state/multicall/reducer';
import mint from '../state/mint/reducer'

const PERSISTED_KEYS = ['user', 'transactions', 'lists', 'profile'];

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  blog: blogReducer,
  userC: userReducer,
  farm: farmReducer,
  pool: poolReducer,
  tokens: tokenReducer,
  ude: udeReducer,
  wallet: walletReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  block: blockReducer,
  farms: farmsReducer,
  pools: poolsReducer,
  mint,
  // Exchange
  user,
  transactions,
  swap,
  burn,
  multicall,
  lists,
  product: persistReducer(productPersistConfig, productReducer),
  middleware: [...getDefaultMiddleware({ thunk: true }), save({ states: PERSISTED_KEYS })],
  preloadedState: load({ states: PERSISTED_KEYS }),
});

export { rootPersistConfig, rootReducer };
