import PropTypes from 'prop-types';
import React, { useEffect } from "react";
import {useNavigate} from "react-router-dom";

function Redirect({link=""}) {
    const navigate =  useNavigate()

    useEffect(() => {
        window.open(link, '_blank');
        navigate('/')
    }, [link, navigate]);

    return (
        <div>
            <h2>Redirecting...</h2>
        </div>
    );
}
Redirect.propTypes = {
    link: PropTypes.string
}
export default Redirect;